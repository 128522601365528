import React from "react";
import TeamSingle from "../components/TeamSingle";

export default function HomePage() {
  return (
    <>
      <section className="container">
        <div className="row g-6">
          <TeamSingle
            img={"promed-logo.png"}
            name="Telmed Pro."
            title={"Teleconsultation pour les professionnel."}
            android={`${process.env.PUBLIC_URL}/assets/files/promed-release.apk`}
          />

          <TeamSingle
            img={"telmed-logo.png"}
            name="Telmed"
            title={"Teleconsultation pour les patients."}
            android={`${process.env.PUBLIC_URL}/assets/files/telmed-release.apk`}
          />

          <TeamSingle
            img={"solisakane-logo.png"}
            name="Solisakane"
            title={"Application de video chat."}
            android={`${process.env.PUBLIC_URL}/assets/files/solisakane-release.apk`}
          />
        </div>
      </section>
    </>
  );
}
